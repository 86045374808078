import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { loginRequest, msalConfig } from './authConfig';
import { useMsal } from "@azure/msal-react";
import './App.css';
import Login from './Components/Login';
import Dashboard from './Components/Dashboard/Dashboard';
import HealthCheck from './Components/Dashboard/HealthCheck';
import Modal from './Components/common/Modal';
import SmsPopup from './Components/common/Modal/SMS/SmsPopup';
import Unzip from './Components/common/Modal/Unzip/Unzip';
import FileUpload from './Components/FileUpload';
import UserList from './Components/User/UserList';
import UserGroup from './Components/User/UserGroup';
import MyHelp from './Components/MyHelp';
import NewFolder from './Components/common/Modal/NewFolder';
import RenameFolder from './Components/common/Modal/RenameFolder';
import BulkUploadPopup from './Components/common/Modal/bulkUploadPopup';
import ErrorBoundary from './Components/common/ErrorBoundary';
import Preview from './Components/common/Modal/Preview';
import DownloadZip from './Components/common/Modal/Preview/DownloadZip';
import Filescan from './Components/Filescan/filescanList'


function Root() {
    const navigate = useNavigate();
    const location = useLocation();
    const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
    const clientId = localStorage.getItem('clientId');
    let [userReqURL,setUserReqURL] = useState('/mdc/auth/media');
    const { instance, accounts } = useMsal(msalConfig);
    const activeAccount = accounts[0];
    let pathname = location.pathname;

    useEffect(() => {
        const handleClientId = () => {
            if (clientId) {
                setAccessToken(localStorage.getItem('accessToken'));
                if (location.pathname === '/') {
                    navigate('/mdc/auth/media');
                }
            } else if (activeAccount) {
                const fetchData = async () => {
                    try {
                        await instance.initialize();
                        const response = await instance.acquireTokenSilent({
                            ...loginRequest,
                            account: activeAccount,
                        });
                        localStorage.setItem('accessToken', response.accessToken);
                        setAccessToken(response.accessToken);
                        if (location.pathname === "/") {
                            navigate('/mdc/auth/media');
                        }
                    } catch (error) {
                        console.error("Authentication error:", error);
                        navigate('/');
                    }
                };
                fetchData();
            }
        };
        handleClientId();
    }, [activeAccount, instance, navigate, clientId, location.pathname]);

    useEffect(() => {
        if(!clientId && accessToken !== null && pathname === '/'){
            let redirectTo = userReqURL;
            if(redirectTo === '/')
            {
              redirectTo = '/mdc/auth/media';
            }
            navigate(redirectTo);
        }
        if(accessToken === null)
        {
            setUserReqURL(pathname);
        }
    },[accessToken]);

    
    return (
        <div>
            <ErrorBoundary>
                <Routes>
                    <Route path='/devmsfhealth' element={<HealthCheck />} />
                    <Route path='/login' element={<Login />} />
                    {(accessToken !== ''  && accessToken !== null) ? (
                        <>
                            <Route exact path='/mdc/auth/media/v1/*' element={<MyHelp />} />
                            <Route path='/mdc/auth/media/*' element={<Dashboard />} />
                            <Route path='/mdc/auth/media/*?upload=1' element={<Modal />} />
                            <Route path='/mdc/auth/media/*?bulkupload=1' element={<BulkUploadPopup />} />
                            <Route path='/mdc/auth/media/*?sms_config=1' element={<SmsPopup />} />
                            <Route path='/mdc/auth/media/*?unzip=1' element={<Unzip />} />
                            <Route path='/mdc/auth/media/*?newfolder=1' element={<NewFolder />} />
                            <Route path='/mdc/auth/media/*?rename=1' element={<RenameFolder />} />
                            <Route path='/mdc/preview/*' element={<Preview />} />
                            <Route path='/mdc/download/*' element={<DownloadZip />} />
                            <Route path='/user/list' element={<UserList />} />
                            <Route path='/filescan' element={< Filescan/>} />
                            <Route path='/user/list?edituser=1' element={<UserList />} />
                            <Route path='/user/add' element={<UserGroup />} />
                            <Route path='/fileUpload' element={<FileUpload />} />
                            <Route path='/*' element={<Navigate replace to='/mdc/auth/media' />} />
                        </>
                    ) : (
                        <>
                            <Route exact path='/' element={<Login redirectUserTo='/mdc/auth/media' />} />
                            <Route path='/*' element={<Navigate replace to='/' />} />
                        </>
                    )}
                </Routes>
            </ErrorBoundary>
        </div>
    );
}

export default Root;


