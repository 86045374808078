import React, { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSearchParams } from "react-router-dom";
import './modal.css';
import UploadIcon from '../../../images/icon_upload.gif';
import PearsonLogo from '../../../images/pearson_logo.png';
import ProgressBarGif from '../../../images/prog_bar.gif';
import ProgressBarTopGif from '../../../images/prog_top.gif';
import ProgressBarLeftGif from '../../../images/prog_left.gif';
import { SOCKET_ENDPOINT } from '../../utils/Constants'
import apiService from '../../utils/apiService';
import _ from "lodash";


const BulkUploadPopup = props => {
  const [filesfound, setfilesfound] = useState(true);
  const modalRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  let foundFiles = [];

  let divStyle = {
    display: props.displayModal ? 'block' : 'none',
    overflowY: "auto"

  };
  let [searchParams, setSearchParams] = useSearchParams();

  function closeModal(e) {
    console.log("closeModal--------------");

    e.stopPropagation()
    props.onModalPopupStateChange(props.displayModal, e);
    if (searchParams.has('bulkupload')) {
      searchParams.delete('bulkupload');
      setSearchParams(searchParams);
      //dispatch(resetAppState({message:""}));
    }
  }

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone(
    {
      multiple: true,
      directory: true,
    }
  );

  let [uploadProgress, setUploadProgress] = useState('0%');
  const [ws, setWs] = useState(null);
  let [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const startTimeRef = useRef(startTime);
  let [netSpeed, setNetSpeed] = useState(0);
  let [ibytes, setIbytes] = useState(0);
  let [itotal, setITotal] = useState(0);
  let [fileUploadStartTime, setFileUploadStartTime] = useState('');
  let ibytesRef = useRef(ibytes);
  let [wsTransmittedReceivedData, setWsTransmittedReceivedData] = useState(0);
  let wsTransmittedReceivedDataRef = useRef(wsTransmittedReceivedData);
  let [displayFileSection, setDisplayFileSection] = useState(false);
  let fileName = [];
  let fileSize = [];
  let [successFileTableData, setSuccessFileTableData] = useState([])
  const [message, setMessage] = useState('');
  const [filescan, setFilescan] = useState([])
    ;
  const fetchFileScanAccess = async () => {
    try {
      const response = await apiService.get("getfilescan");
      const data = _.get(response, 'data', []);

      setFilescan(data); //  Update state
    } catch (error) {
      console.error("Error fetching file scan access:", error);
      setMessage("Error loading data.");
    }
  };

  // Load data on component mount
  useEffect(() => {
    let count = 0;

    const interval = setInterval(() => {
      if (count < 6) {
        fetchFileScanAccess();
        console.log(`fetchFileScanAccess() called #${count + 1}`);
        count++;
      } else {
        clearInterval(interval);
      }
    }, 1000); // Calls every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);


  // WebSocket connection
  useEffect(() => {
    const newWs = new WebSocket(`${SOCKET_ENDPOINT}socket/`);
    // const newWs = new WebSocket("ws://localhost:8081/socket/");
    setWs(newWs);

    newWs.onopen = () => {
      console.log("Connected to WebSocket");

      // Send a greeting message when connected
      const message = JSON.stringify({ type: "fileScanData", data: filescan });
      newWs.send(message);
    };

    newWs.onmessage = (event) => {
      console.log("Message from server:", event.data);
    };

    return () => {
      if (newWs) {
        newWs.close();
      }
    };
  }, []);

  // Wait for filescan to be updated, then send it to WebSocket
  useEffect(() => {
    if (filescan && ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ type: "fileScanData", data: filescan }));
    }
  }, [filescan, ws]);

  const sendFileInChunks = (file, index) => {
    if (!ws || ws.readyState !== WebSocket.OPEN) {
      console.error('WebSocket is not open or is not available.');
      return;
    }
    if (ws || ws.readyState == WebSocket.OPEN) {
      ws.send(JSON.stringify({ type: "fileScanData", data: filescan }));
      ws.onmessage = function incoming(data) {
        data = JSON.parse(data.data);
        if (data.topic === 'time') {
          startTimeRef.current = data.data;
          setFileUploadStartTime(data.data);
        } else {
          wsTransmittedReceivedDataRef.current = data.data;

          setWsTransmittedReceivedData(wsTransmittedReceivedDataRef.current);

          const currentTime = new Date().getTime();
          const elapsed = (parseFloat(currentTime) - parseFloat(startTimeRef.current)) / 1000;
          setElapsedTime(elapsed);

          // Log the cumulative value for debugging
          console.log("wsTransmittedReceivedData (cumulative):", wsTransmittedReceivedDataRef.current);
        }
      };
    }



    const chunkSize = 1024 * 1024 * 10; // 10MB chunks
    let offset = 0;

    const reader = new FileReader();


    reader.onload = () => {
      const chunkSize = 1024 * 1024 * 10; // 10MB chunks
      let offset = 0;

      const reader = new FileReader();

      reader.onload = (progressEvent) => {
        const { loaded, total } = progressEvent;

        ibytesRef.current = ibytesRef.current + loaded;
        setIbytes(ibytesRef.current);
        const chunk = reader.result;

        ws.send(chunk);

        offset += chunkSize;
        if (offset < file.size) {
          readNextChunk();
        } else {
          index = index + 1;
          sendEndMessage(file);
          if (acceptedFiles.length > index) {

            sendFileInChunks(acceptedFiles[index], index);
          }
        }
      };

      const readNextChunk = () => {
        const blob = file.slice(offset, offset + chunkSize);
        reader.readAsArrayBuffer(blob);
      };

      readNextChunk();
    };

    const readNextChunk = () => {
      const blob = file.slice(offset, offset + chunkSize);
      reader.readAsArrayBuffer(blob);
    };
    sendStartMessage(file);
    readNextChunk();
  };

  const sendPath = () => {
    const sendWebSocketData = (data) => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(data);
      } else {
        console.error('WebSocket is not open or is not available.');
      }
    };
    sendWebSocketData(`${props.currentDir} Bulk_Upload_path`);
    sendWebSocketData(`${props.currentDir} Bulk_Upload_path`);

    sendWebSocketData(
      JSON.stringify({ type: "fileScanData", data: filescan?.data || filescan })
    );  

  }

  const sendStartMessage = (file) => {
    const sendWebSocketData = (data) => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(data);
      } else {
        console.error('WebSocket is not open or is not available.');
      }
    };

    sendWebSocketData(`${file.path} Bulk_Upload_starts`);
    sendWebSocketData(
      JSON.stringify({ type: "fileScanData", data: filescan?.data || filescan })
    );  }

  const sendEndMessage = (file) => {
    console.log("sendEndMessage file", file.path);
    const sendWebSocketData = (data) => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(data);
      } else {
        console.error('WebSocket is not open or is not available.');
      }
    };

    sendWebSocketData(`${file.path} Bulk_Upload_ends`);
  }

  const handleFileUpload = (e) => {
    e.preventDefault();
    setfilesfound(false)
    const forbiddenExtensions = [];
    let cummulateFileSizeToUpload = 0;
    let filename = []
    console.log("handleFileUpload acceptedFiles", acceptedFiles);
    acceptedFiles.map(file => {
      cummulateFileSizeToUpload += file.size;
      fileName.push(file.path);
      fileSize.push(file.size);
    });
    console.log("handleFileUpload fileName", fileName);

    const payload = { currentpath: props.currentDir, filename: fileName[0] }
    apiService.post("fileupload", payload)
    let sucessfileInfo = [];
    for (let index = 0; index < fileName.length; index++) {

      let trColor = '';
      if (index % 2 === 0) {
        trColor = "backgroundColor:'#DDDDDD'";
      }
      sucessfileInfo.push(<tr align="left" key={index} style={{ trColor }}>
        <td align="left" valign="middle" style={{ border: "1px solid black" }}>{fileName[index]}</td>
        <td align="left" valign="middle" style={{ border: "1px solid black" }}>{fileSize[index]}</td>
      </tr>);
    }

    setSuccessFileTableData(sucessfileInfo);

    if (cummulateFileSizeToUpload > 5 * 1024 * 1024 * 1024) {
      alert("Files/Folders size exceeds 5GB. Please select content less than 5GB to upload.")
      setfilesfound(false)
    }
    else {

      if (acceptedFiles && acceptedFiles.length > 0) {

        const hasForbiddenExtensions = acceptedFiles.some(file => {
          const fileExtension = file.name.split('.').pop().toLowerCase();
          return forbiddenExtensions.includes(fileExtension);
        }
        );

        if (hasForbiddenExtensions) {
          alert('You cannot upload files with these extensions')
        }
        else {
          // const payload ={currentpath:props.currentDir,filename:fileName[0]}
          console.log("handleFileUpload props.currentDir", props.currentDir);
          console.log("handleFileUpload filename", fileName[0]);

          sendPath();
          let selectedFileSize = acceptedFiles.reduce((total, file) => total + file.size, 0)
          setITotal(selectedFileSize)
          sendFileInChunks(acceptedFiles[0], 0);
          setUploadProgress('1%');
        }
      }

    }
  };

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const formatTime = (timeInSeconds) => {
    const hours = (!isNaN(timeInSeconds) && timeInSeconds != 'Infinity') ? Math.floor(timeInSeconds / 3600) : 0;
    const minutes = (!isNaN(timeInSeconds) && timeInSeconds != 'Infinity') ? Math.floor((timeInSeconds % 3600) / 60) : 0;
    const seconds = (!isNaN(timeInSeconds) && timeInSeconds != 'Infinity') ? Math.floor(timeInSeconds % 60) : 0;
    return `${String(hours).padStart(2, '0')} : ${String(minutes).padStart(2, '0')} : ${String(seconds).padStart(2, '0')}`;
  };
  useEffect(() => {
    if (wsTransmittedReceivedData !== undefined && itotal > 0) {

      let uploadPercentage = ((wsTransmittedReceivedData / itotal) * 100).toFixed(2) + '%';
      setUploadProgress(uploadPercentage);

      if (parseFloat(uploadPercentage) === 100) {
        setDisplayFileSection(true);
      }

      // Ensure elapsedTime is not zero to avoid division by zero
      let bSpeed = elapsedTime > 0 ? (wsTransmittedReceivedData / elapsedTime) : 0;
      let bitSpeed = bSpeed * 8; // Convert bytes/sec to bits/sec
      let kbitSpeed = (bitSpeed / 1000).toFixed(2); // Convert to kilobits/sec
      // Ensure valid speed values before updating state
      setNetSpeed(isNaN(kbitSpeed) ? 0 : kbitSpeed);

      let bytesRemaining = itotal - wsTransmittedReceivedData;
      let dtRemaining = kbitSpeed > 0 ? (bytesRemaining / (kbitSpeed * 1000)) : 0; // Avoid NaN
      setTimeRemaining(Math.trunc(dtRemaining));
    }
  }, [itotal, wsTransmittedReceivedData, elapsedTime]);

  if (files) {
    files.forEach(file => console.log("files::::", file.key));

  }
  function checkFileAndFolderExistence() {
    let foundFolders = [];
    const directories = localStorage.getItem('directories').split(',');
    files.forEach((file) => {
      let filePath = file.key.split('/');
      if (filePath.length > 1) {
        let foldervalue = directories.includes(filePath[1])
        if (foldervalue) {
          const folderEntry = "Directory - " + filePath[1];
          if (!foundFolders.includes(folderEntry)) {
            foundFolders.push(folderEntry);
            foundFiles.push(`Directory - ${filePath[1]}`);
          }
        }
      } else {
        let filevalue = directories.includes(filePath[0])
        if (filevalue) {
          foundFiles.push(filePath[0]);
        }
      }
    });
  }

  checkFileAndFolderExistence();

  const handleMouseDown = (e) => {
    const rect = modalRef.current.getBoundingClientRect();
    setIsDragging(true);
    setOffset({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const modal = modalRef.current;
    const containerWidth = window.innerWidth;
    const containerHeight = window.innerHeight;
    const modalRect = modal.getBoundingClientRect();
    let newLeft = e.clientX - offset.x;
    let newTop = e.clientY - offset.y;
    if (newLeft < 0) newLeft = 0;
    if (newTop < 0) newTop = 0;
    if (newLeft + modalRect.width > containerWidth) newLeft = containerWidth - modalRect.width;
    if (newTop + modalRect.height > containerHeight) newTop = containerHeight - modalRect.height;
    modal.style.left = `${newLeft}px`;
    modal.style.top = `${newTop}px`;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };





  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div ref={modalRef} className="modal-content" onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}
        style={{ position: "absolute", height: "550px", width: "640px", backgroundColor: "white", border: "1px solid black", borderRadius: "20px", cursor: isDragging ? "grabbing" : "grab" }}>

        <div style={{ display: displayFileSection ? 'none' : 'block', overflowY: "auto" }}>

          <table border="0" width="100%" cellSpacing="3" cellPadding="0" className='modal-content-table'>
            <tr align="left" valign="top">
              <td align="left" valign="middle"><span className="pagetitle">&nbsp;Bulk File Uploader</span></td>
              <td align="right" valign="middle"><img src={PearsonLogo} title="Pearson Education" alt="Pearson Education"
                width="85" height="28" hspace="0" vspace="0" border="0" /></td>
              <td><span
                className="close" onClick={closeModal}
              >&times;
              </span>
              </td></tr>
          </table>

          <div id="table" style={{ position: "absolute", top: "46px", width: "550px", height: "120px", zIndex: "1" }}>
            <table align="center" border="0" width="" cellSpacing="0" cellPadding="0">
              <tr align="left" valign="top">
                <td align="left" valign="top" width="90" style={{ backgroundcolor: "#E9EAEC" }}><img src={UploadIcon} alt="Upload Files" width="91" height="70" hspace="0" vspace="0" border="0" /></td>
                <td align="center" valign="middle" className="instructions">You may uploadS file of any size. <br /><br />
                  File(s) uploaded will be located in the following directory:<br />
                  <font style={{ color: 'red' }}>
                    {decodeURIComponent(props.currentDir)}
                  </font>
                  <br /><br />

                  <div onSubmit={handleFileUpload} style={{ overflowY: "auto" }}>
                    <div {...getRootProps()} style={dropzoneStyle}>
                      <input {...getInputProps()} />
                      <p>Drag and drop some files here, or
                        <span style={{ color: 'red' }}> click</span> to select files</p>
                      <aside>
                        <h4>Files</h4>
                        <ul>{files}</ul>
                      </aside>

                    </div>
                    <div>
                      {filesfound && foundFiles.length > 0 ?
                        <h4>
                          <span style={{ color: "black" }}>List of uploaded files/folder already exist in the following directory:</span>
                          <br />
                          <span style={{ color: "red" }}>"{decodeURIComponent(props.currentDir)}"</span>
                        </h4>
                        : ""}
                    </div>


                    {filesfound && foundFiles.length > 0 ?
                      <div style={rewriteUploadStyle}>
                        <table border="0" width="100%" height="50px" cellSpacing="0" cellPadding="0" >
                          <tr align="center">
                            <div >
                              <td >
                                <span className='upload-folder-ErrorMsg'>
                                  <aside>
                                    <ul >
                                      {foundFiles.map((file, index) => (
                                        <li key={index}>{file}</li>
                                      ))}
                                    </ul>
                                  </aside>
                                  <br />
                                </span>
                              </td>
                            </div>
                          </tr>
                        </table>
                      </div> : ""}
                    <br />
                    {uploadProgress != '0%' &&
                      <table style={{ border: '0', width: '100%', backgroundColor: "#E9EAEC", marginTop: "50px" }}>

                        {wsTransmittedReceivedData < itotal &&

                          (<tr align="center" style={{ verticalAlign: "top" }}>
                            <td align="center" style={{ verticalAlign: "middle" }} className="pagetitle" colSpan="2">File Upload in Progress, Please don't close window</td>
                          </tr>)
                        }

                        {wsTransmittedReceivedData == itotal &&

                          (<tr align="center" style={{ verticalAlign: "top" }}>
                            <td align="center" style={{ verticalAlign: "middle" }} className="pagetitle" colSpan="2">File Upload is Completed</td>
                          </tr>)
                        }

                        <tr align="left" style={{ verticalAlign: "top" }} >
                          <td align="left" style={{ verticalAlign: "middle" }} colSpan="2">

                            <table summary="This table displays the progress bar" border="0" width='100%' cellSpacing="0" cellPadding="0" background-color="#FFFFFF">
                              <tr align="left" style={{ verticalAlign: "top" }}>
                                <td align="left" style={{ verticalAlign: "top", paddingTop: "13px" }} rowSpan="3" width="3"><img src={ProgressBarLeftGif} alt="" width="3" height="16" hspace="0" vspace="0" border="0" colSpan="2" /></td>
                                <td align="left" style={{ verticalAlign: "top" }}><img src={ProgressBarTopGif} alt="" width={uploadProgress} height="4" hspace="0" vspace="0" border="0" style={{ marginBottom: "-5px" }} /></td>
                              </tr>

                              <tr align="left" style={{ verticalAlign: "top" }}>
                                <td align="left" style={{ verticalAlign: "top" }}><span><img src={ProgressBarGif} alt="" width={uploadProgress} height="10" hspace="0" vspace="0" border="0" /></span>

                                </td>
                              </tr>

                              <tr align="center" style={{ verticalAlign: "top" }}>
                                <td align="center" style={{ verticalAlign: "middle" }} className="instructions" colSpan="2">Status: {wsTransmittedReceivedData} bytes of {itotal} sent (at {netSpeed} Kbps)</td>
                              </tr>

                            </table>
                            <table border="0" width='100%' cellSpacing="0" cellPadding="0" >
                              <tr align="center" verticalAlign="top">
                                <td align="left" style={{ verticalAlign: "middle" }} className="instructions"><strong>Est Time left:&nbsp;</strong>{formatTime(timeRemaining)}</td>
                                <td align="right" style={{ verticalAlign: "middle" }} className="instructions"><strong>Elapsed time:&nbsp;</strong>{formatTime(elapsedTime)}</td>
                              </tr>
                            </table>
                          </td>
                        </tr>

                      </table>
                    }
                    <br />
                    <br />
                  </div>
                  {filesfound && foundFiles.length > 0 ?
                    <span style={{ paddingBottom: "30px" }}>
                      <button type="button" className="close_button" style={{ margin: "5px" }} onClick={closeModal}>
                        Cancel
                      </button>
                      <button type="submit" style={{ margin: "5px", marginLeft: "30px" }} onClick={handleFileUpload} >Replace Files</button>
                    </span>
                    :
                    <button type="submit" style={{ margin: "5px" }} onClick={handleFileUpload}>Upload Files</button>
                  }
                </td>
              </tr>
            </table>

          </div>
        </div>

        <div style={{ display: displayFileSection ? 'block' : 'none', fontSize: '110%', fontFamily: "inherit", position: "relative", width: "100%", height: "100%", overflow: "scroll" }}>
          <table style={{ border: "1px solid black", cellPadding: "5", width: "95%", align: "center", marginLeft: "auto", marginRight: "auto", marginTop: "25px" }}>

            <tr>
              <td colSpan="2" style={{ backgroundColor: "#0066cc" }}>
                <font style={{ color: "white", size: "+1", align: "center" }}>Files Uploaded</font>
              </td>
            </tr>

            <tr style={{ backgroundColor: "#CCCFF3" }}>
              <td style={{ fontSize: "110%", border: "1px solid black" }}>
                <nobr>File Name</nobr>
              </td>

              <td style={{ fontSize: "110%", align: "right", border: "1px solid black" }}>
                <nobr>File size</nobr>
              </td>
            </tr>
            {successFileTableData}
          </table>
          {/* <p> */}
          <center> <button onClick={closeModal} className="closeButton">close</button> </center>

          {/* </p> */}

          <div style={{ bottom: "30px", width: "100%", position: "static" }}>
            <hr />
            <table align="center">
              <tr><td align="center">File Upload Complete</td></tr>
              <tr>
                <td>
                  Status: {ibytes} bytes of {itotal} sent (at {Math.abs(netSpeed)} Kbps)<br />
                  Est time left: 00:00:00<br />
                  Elapsed time: {Math.abs(elapsedTime)}<br />
                </td>
              </tr>
            </table>
          </div>
        </div>

      </div>
    </div>
  );

}



const dropzoneStyle = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '40px',
  textAlign: 'center',
  cursor: 'pointer',
  opacity: '0.7',
  boxShadow: '3px 3px 5px 0px rgba(0,0,0,0.3)',
  maxHeight: '100px',
  overflowY: 'auto',
};

const rewriteUploadStyle = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  paddingBottom: "5px",
  paddingTop: "20px",
  textAlign: 'center',
  cursor: 'pointer',
  opacity: '0.7',
  boxShadow: '3px 3px 5px 0px rgba(0,0,0,0.3)',
  maxHeight: '90px',
  overflowY: 'auto',
};

export default BulkUploadPopup;