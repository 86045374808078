export const PUT_LOGIN_REQUEST = 'actions/login';
export const LOGIN_REQUEST = "loginRequest";
export const LOGIN_API = "login";
export const PUT_LOGOUT_REQUEST = 'actions/logout';
export const LOGOUT_REQUEST ="logoutRequest";
export const GET_SUB_DIRECTORIES = "actions/directories/getDirectoreies";
export const PUT_SUB_DIRECTORIES_REQUEST = "actions/directories/directoriesList";
export const DIRECTORY_PATH = '';
export const UPLOAD_FILES = 'actions/uploadfiles';
export const UPLOAD_PATH = 'upload'
export const MEDIA_FILE_UPLOADED_SUCCESSFULLY = "reducer/fileuploaded";
export const RESET_MEDIA_SUCCESS_MSG_STATE = "actions/resetmediamessage";
export const SUBMIT_USER_GROUP_DETAILS = "actions/submitusergroupdetails";
export const GET_DIRECTORIES_OPTIONS= "actions/getdirectoriesOptions";
export const GET_ACTIVEDIRECTORIES_USERS= "searchUsers";
export const GET_DIR_OPTIONS_LIST = "directories";
export const PUT_DIRECTORIES_OPTIONS = "statemanagement/updatedirectoryoptions";
export const PUSH_MEDIA_FILE_ACTION = "actions/pushfile";
export const PUSH_FUNC_API = "pushmediafile";
export const PUT_SUCCESS_LIVE_URLS = "update/state/production/urls";
export const EMPTY_MEDIA_SERVER_URLS = 'empty/mediaserver/urls';
export const GET_FILE_SMS_INFO = 'actions/getsmsfileinfo';
export const SMS_FILE_API = 'getsmsconfigfiledetails';
export const PUT_SMS_FILE_INFO = 'statemanagement/updatesmsinfo';
export const EMPTY_SMS_STATEMANAGEMENT_INFO = 'empty/sms/statemanagement';
export const ADD_SMS_INFO = 'actions/addsmsinfo';
export const SMS_CONFIG_API = 'smsconfig';
export const DELETE_SMS_INFO = 'actions/deletesmsinfo';
export const DELETE_SMS_CONFIG_API = 'deletesmsconfig';
export const GET_ACTIVE_DIRECTORY_USER = 'getactivedirectoryuser';
export const ACTIVE_USER_DIRECTORY_LIST = 'activeuserdirectorylist';
export const ADD_AD_USER = 'actions/addactivedirectoryuser';
export const ADD_USER_API = 'adduser';
export const GET_USER_LIST = 'actions/getuserlist';
export const GET_USER_LIST_API = 'userlist';
export const PUT_USER_LIST = 'statemanagement/userlist';
export const ENABLE_DISABLE_USER = 'enabledisableuser';
export const ENABLE_DISABLE_USER_API = 'disableuser';
export const DELETE_USER = 'actions/deleteUser';
export const DELETE_USER_API = 'deleteuser';
export const UNZIP_FOLDER = 'actions/unzip';
export const UNZIP_API = 'unzip';
export const USER_DISPLAY_MSG = 'usersuccessmessage';
export const EMPTY_GENERIC_SUCCESS_MESSAGE = 'emptygenericsuccessmessage';
export const CREATE_NEW_FOLDER_API = 'newfolder';
export const CREATE_NEW_FOLDER = 'actions/newfolder';
export const NO_DIR_USER_FOUND = 'actions/nodiroruserfound';
export const DELETE_SELECTED_FILES_LIST = 'actions/deleteselectedfiles';
export const DELETE_FILE_API = 'deletefile';
export const PUT_FILE_DELETE_INFO = 'statemanagement/deleteinfo';
export const RENAME_FOLDER = 'actions/rename';
export const RENAME_FOLDER_API = 'rename';
export const EDIT_USER_INFO = 'action/edituserinfo';
export const EDIT_USER_API = 'editUser';
export const EDIT_USER_DETAILS = 'statemanagement/edituserdetails';
export const UPDATE_USER_DETAILS = 'action/updateuser';
export const UPDATE_USER_API = 'updateuser';
export const BULK_DOWNLOAD_FILES = 'action/download';
export const BULKDOWNLOAD_FILE_API = 'download';
export const CLOSE_BULK_DOWNLOAD_POPUP = 'closeBulkDownloadPopup';
export const EMPTY_BULK_DOWNLOAD_POPUP_STATEMANAGEMENT_INFO = "resetBulkUploadStateManagement"
export const FILESCAN_ACCESS = "fileScanAccess"



