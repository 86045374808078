import Axios,{isCancel} from 'axios';
import { API_URL, MS_URL } from './Constants.js';
import {  PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from "../../authConfig";


const msalInstance = new PublicClientApplication(msalConfig);

const apiService = {
    async post(url,payload){
        //await this.getToken();
        //console.log(await this.acquireAccessToken())
        //let headers = await this.idToken();
        let headers = await this.acquireAccessToken();
        return await Axios.post(`${API_URL}/${url}`,payload, headers).catch(function (error) {
            if (error.response) {
              if(error.response.data.status_code == 401 && error.response.data.message == 'Unauthorized')
              {
                localStorage.removeItem("accessToken");
                localStorage.clear();
                window.location.href = MS_URL;
              }
              return error.response;
            } else {
              console.log('Error', error.message);
            }
          })
          .then(response => {
            if(localStorage.getItem('user.role') === undefined || localStorage.getItem('user.role') === null)
            {
              localStorage.setItem('user.role', response.headers['role']); 
            }
            return response;
          });
    },

    async get(url,payload){
        //let headers = await this.idToken();
        let headers = await this.acquireAccessToken();
        return await Axios.get(`${API_URL}/${url}`,headers).catch(function (error) {
                if (error.response) {
                if(error.response.data.status_code == 401 && error.response.data.message == 'Unauthorized')
                {
                    localStorage.removeItem("accessToken");
                    localStorage.clear();
                    window.location.href = MS_URL;
                }
                return error.response;
                } else {
                console.log('Error', error.message);
                }
            })
            .then(response => {

                if(localStorage.getItem('user.role') === undefined || localStorage.getItem('user.role') === null)
                {
                  localStorage.setItem('user.role', response.headers['role']); 
                }
                return response;
            });
    },

        // PUT method (new method to add)
        async put(url, payload) {
          let headers = await this.acquireAccessToken();
          return await Axios.put(`${API_URL}/${url}`, payload, headers).catch(function (error) {
              if (error.response) {
                  if (error.response.data.status_code == 401 && error.response.data.message == 'Unauthorized') {
                      localStorage.removeItem("accessToken");
                      localStorage.clear();
                      window.location.href = MS_URL;
                  }
                  return error.response;
              } else {
                  console.log('Error', error.message);
              }
          }).then(response => {
              if (localStorage.getItem('user.role') === undefined || localStorage.getItem('user.role') === null) {
                  localStorage.setItem('user.role', response.headers['role']);
              }
              return response;
          });
      },

    async postMedia(url,payload, options){
        try{
            //let headers = await this.idToken();
            let headers = await this.acquireAccessToken();
             // Merge defaultHeaders and options into a single object
      const requestConfig = {
        ...headers,
        ...options
      };
            return await Axios.post(`${API_URL}/${url}`,payload, requestConfig).catch(function (error) {
                if (error.response) {
                  console.log(error.response.data);
                  if(error.response.data.status_code == 401 && error.response.data.message == 'Unauthorized')
                  {
                    localStorage.removeItem("accessToken");
                    localStorage.clear();
                    window.location.href = MS_URL;
                  }
                  return error.response;
                } else {
                  console.log('Error', error.message);
                }
              })
              .then(response => {
                if(localStorage.getItem('user.role') === undefined || localStorage.getItem('user.role') === null)
                {
                  localStorage.setItem('user.role', response.headers['role']); 
                }
                return response;
              });;
        }catch(err) {
            if(Axios.isCancel(err))
            {
                console.log("I am cancelled");
            }
        }
    },

    async acquireAccessToken() {
      //#region application token
    const clientId = localStorage.getItem('clientId');
    const authResult = localStorage.getItem('accessToken');
    
    let headers;

    if (clientId) {
        // If clientId exists in localStorage, use its associated access token
        headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authResult}` 
            }
        };
        //#endregion application token
    } else {
        // Otherwise, use the access token obtained through acquireTokenSilent
        const activeAccount = msalInstance.getActiveAccount();
        const accounts = msalInstance.getAllAccounts();

        if (!activeAccount && accounts.length === 0) {
            window.location.href = MS_URL;
            return;
        }

        const request = {
            ...loginRequest,
            account: activeAccount || accounts[0]
        };

        const authResult = await msalInstance.acquireTokenSilent(request);

        headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authResult.accessToken}`
            }
        };
    }

    return headers;
}
}


export default apiService;


